
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import EditLocationModal from "@/views/sbmx/modals/EditLocationModal.vue";
import { Location } from "@/store/modules/AuthModule";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "account-locations",
  components: { EditLocationModal },
  setup() {
    //const locations = ref([]);
    let l = new Location();
    let empty = new Location();
    const selectedLocation = ref(l);
    const emptyLocation = ref(empty);
    const store = useStore();

    const setActiveLocation = function (location) {
      selectedLocation.value = location;
    };

    const clearActiveLocation = function () {
      let n = new Location();
      Object.assign(selectedLocation.value, n);
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Locations", ["Account"]);
      store.dispatch(Actions.REFRESH_ACCOUNT_LOCATIONS);
    });
    return {
      selectedLocation,
      setActiveLocation,
      clearActiveLocation,
      emptyLocation,
    };
  },
});
